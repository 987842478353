import T from "../../components/forms/TextField.module.css";

import { useForm } from "@tanstack/react-form";
import type { ReactNode } from "react";
import { Input, Label, NumberField } from "react-aria-components";
import { ariaTanstackFieldProps } from "../../components/forms/ariaTanstackFieldProps.tsx";
import { CurrencyBalance, CurrencySymbol } from "../../components/l10n/Currency.tsx";
import { ModalResult, WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useConfig } from "../../config.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { enableDisableAutomatedTopUpMutation } from "./billingAccountQuery.ts";
import type { EBillingAccount } from "./billingLogic.tsx";
import { accountCardMethodsAtom, getPrimaryCard } from "./BoundMethod.ts";
import { ConversionMessage } from "./ConversionMessage.tsx";
import { ErrorTexts } from "./payment_forms/components.tsx";
import { PaymentFees } from "./PaymentFees.tsx";

export interface AutomatedTopUpModalProps {
    billingAccount: EBillingAccount;
}

export function AutomatedTopUpModalContent({ billingAccount }: AutomatedTopUpModalProps) {
    //#region Hooks
    const { minimumTopupAmount } = useConfig();
    const toggleAutomatedTopUpMutation = useStandardMutation(enableDisableAutomatedTopUpMutation);

    const { id, recurring_payment_threshold, recurring_payment_amount, is_recurring_payment_enabled } =
        billingAccount.account;

    const cardMethods = useSuspenseQueryAtom(accountCardMethodsAtom(id));
    const primaryCardMethod = getPrimaryCard(cardMethods);

    const form = useForm({
        defaultValues: {
            threshold: recurring_payment_threshold ?? minimumTopupAmount,
            amount: recurring_payment_amount ?? minimumTopupAmount,
        },
        async onSubmit({ value: { threshold, amount } }) {
            await toggleAutomatedTopUpMutation.mutateAsync({
                id,
                body: {
                    recurring_payment_threshold: threshold,
                    recurring_payment_amount: amount,
                    is_recurring_payment_enabled: true,
                },
            });
        },
    });
    const isSubmitting = form.useStore((s) => s.isSubmitting);
    //#endregion

    return (
        <WModalContent
            title="Automated Top Up"
            label={!is_recurring_payment_enabled ? "Enable" : "Save"}
            modalAction={async () => {
                await form.handleSubmit();
                return form.state.isValid ? ModalResult.CLOSE : ModalResult.KEEP_OPEN;
            }}
        >
            <div className="w-50%">
                <form.Field
                    name="threshold"
                    validators={{
                        // `NaN` when the field is empty
                        onBlur: ({ value }) => (Number.isNaN(value) || value <= 0 ? "Amount should be positive" : null),
                    }}
                >
                    {(api) => (
                        <NumberField
                            className={T.NumberField}
                            autoFocus
                            isDisabled={isSubmitting}
                            {...ariaTanstackFieldProps(api)}
                        >
                            <Label className={T.Label}>
                                When balance goes below (<CurrencySymbol />)
                            </Label>
                            <Input className={T.Input} />
                            <ErrorTexts errors={api.state.meta.errors} />
                        </NumberField>
                    )}
                </form.Field>
            </div>

            <div className="flex gap-0.5rem mt-3">
                <div className="w-50%">
                    <form.Field
                        name="amount"
                        validators={{
                            onBlur: ({ value }) =>
                                Number.isNaN(value) || value < minimumTopupAmount
                                    ? "Amount should not be less than than minimum top up"
                                    : null,
                        }}
                    >
                        {(api) => (
                            <NumberField
                                className={T.NumberField}
                                isDisabled={isSubmitting}
                                {...ariaTanstackFieldProps(api)}
                            >
                                <Label className={T.Label}>
                                    Automatically top up (min. <CurrencyBalance value={minimumTopupAmount} />)
                                </Label>
                                <Input className={T.Input} />
                                <ErrorTexts errors={api.state.meta.errors} />
                            </NumberField>
                        )}
                    </form.Field>
                </div>

                <form.Subscribe selector={(s) => s.values.amount}>
                    {(amount) => (
                        <>
                            <PaymentFees
                                account={billingAccount}
                                amount={amount}
                                fees={primaryCardMethod?.method.fee}
                            />
                            <ConversionMessage
                                account={billingAccount}
                                amount={amount}
                                fees={primaryCardMethod?.method.fee}
                            />
                        </>
                    )}
                </form.Subscribe>
            </div>
        </WModalContent>
    );
}

export function AutomatedTopUpModal({ button, ...props }: AutomatedTopUpModalProps & { button: ReactNode }) {
    return (
        <WModal button={button}>
            <AutomatedTopUpModalContent {...props} />
        </WModal>
    );
}
