import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { atomFamily } from "jotai/utils";
import { atom } from "jotai/vanilla";
import { mergeQueries } from "../../utils/query/mergeQueries.ts";
import { accessListQueryAtom } from "../access/accessListQuery.ts";
import type { AccessDelegationWithType } from "../access/delegation/apiOperations.ts";
import type { ApiTokenWithType } from "../access/token/apiOperations.ts";
import { allAssignedVmsQueryAtom, type VirtualMachineWithAssigned } from "../compute/joinAssignedQuery.ts";
import { networkListQueryAtom, type NetworkListItem } from "../network/networkListQuery.ts";
import type { Price } from "../pricing/resourcePricing.ts";
import { allAssignedServicesQueryAtom, type ManagedServiceWithAssigned } from "../services/joinAssignedQuery.ts";
import type { StorageWithPrice } from "../storage/objectStorage/apiOperations.ts";
import { storageListQueryWithPricesAtom } from "../storage/storageListQuery.ts";

export type BillingLinkedResource =
    | VirtualMachineWithAssigned
    | ManagedServiceWithAssigned
    | StorageWithPrice
    | NetworkListItem
    | ApiTokenWithType
    | AccessDelegationWithType;

function getBaId(item: BillingLinkedResource) {
    return item.$type === "virtual_machine" ? item.billing_account : item.billing_account_id;
}

export function getLinkedPrice(item: BillingLinkedResource): Price | undefined {
    const { $type } = item;
    switch ($type) {
        case "virtual_machine":
        case "bucket":
        case "ip_address":
        case "load_balancer":
        case "managed_service":
            return item.price;

        case "vpc":
            return undefined;

        case "api_token":
        case "access_delegation":
            return undefined;

        default:
            exhaustiveSwitchCheck($type);
    }
}

export const linkedResourcesAtom = atomFamily((billingAccountId: number) =>
    atom((get) => {
        const vmQ = get(allAssignedVmsQueryAtom);
        const storageQ = get(storageListQueryWithPricesAtom);
        const networkQ = get(networkListQueryAtom);
        const accessQ = get(accessListQueryAtom);
        const servicesQ = get(allAssignedServicesQueryAtom);

        return mergeQueries({ vmQ, storageQ, networkQ, accessQ, servicesQ }, (resultSets) => {
            const allItems = resultSets.flatMap((m): BillingLinkedResource[] =>
                // Some of the atoms above are maps, some are arrays, so convert everything to arrays
                m instanceof Map ? [...m.values()] : m,
            );

            return allItems.filter((item) => getBaId(item) === billingAccountId);
        });
    }),
);
